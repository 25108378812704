import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AppConfigService} from "../../config/app-config.service";
import {SendPaymentRequest} from "../models/interfaces/send-payment-request";
import {SendPaymentResponse} from "../models/interfaces/send-payment-response";

@Injectable({
    providedIn: 'root'
})
export class ProviderService {

    private _httpClient = inject(HttpClient);
    private _configService = inject(AppConfigService);

    // TODO call speedPay to do the payment
    public sendPayment(payload: SendPaymentRequest): Observable<SendPaymentResponse> {
        const endpointUrl = `${this._configService.getApiBaseUrl()}${this._configService.getSendPaymentEndpoint()}`;
        return this._httpClient.post<SendPaymentResponse>(endpointUrl, payload);
    }
}