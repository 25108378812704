import {HttpInterceptorFn} from "@angular/common/http";
import {inject} from "@angular/core";
import {SessionStorageService} from "../services/session-storage.service";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
    const sessionStorageService = inject(SessionStorageService);

    const authRequest = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${sessionStorageService.getCMAToken()}`)
    })

    return next(authRequest);
}