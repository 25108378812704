import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {ComplexCardComponent} from "../complex-card/complex-card.component";
import {CurrencyPipe, DatePipe, NgClass, NgIf} from "@angular/common";
import {CmaModalComponent} from "../modal/modal.component";
import {CustomerDetails} from "src/app/speed-pay/models/interfaces/customer-details";
import {EventBusService} from "src/app/core/services/event-bus.service";
import {ListenerEventsType} from "../../models/enums/listener-events-type";
import {EdsInlineBannerComponent} from "@electronds/ng-components-piedmont";

@Component({
    selector: 'cma-landing',
    standalone: true,
    imports: [ComplexCardComponent, CurrencyPipe, DatePipe, NgIf, CmaModalComponent, NgClass, EdsInlineBannerComponent],
    templateUrl: './landing.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingComponent {
    // dependencies
    private _eventBusService = inject(EventBusService);

    @Input()
    public customerDetails: CustomerDetails

    public today = Date.now();
    public isAccountDetailsModalOpen = false;
    public partialPaymentWarning = 'The payment amount is less than the total amount due. If you proceed, your account may incur a late charge or to be subject to disconnection.';
    public creditPaymentWarning = 'The payment amount is greater than the total amount due. A credit will be applied to your account.';

    public openDetailsModal() {
        this.isAccountDetailsModalOpen = true;
        this._eventBusService.emit(ListenerEventsType.ACCOUNT_DETAIL_SHOW);
    }

    public closeDetailsModal() {
        this.isAccountDetailsModalOpen = false;
        this._eventBusService.emit(ListenerEventsType.ACCOUNT_DETAIL_HIDE);
    }
}
