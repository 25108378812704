<div *ngIf="customerDetails"
     class="relative">
    <!-- Total Amount Due-->
    <cma-complex-card [cardTitle]="'Total Amount Due'"
                      [extraContainerCssClasses]="'mb-24'"
                      [extraHeaderCssClasses]="'text-gray-dark justify-between'"
                      [btnIconName]="'./assets/icons/tooltip-base.svg'"
                      [extraBtnCssClasses]="'icon-24 mb-2 !mx-auto'"
                      [rightHeaderAmount]="+customerDetails.paymentInfo.totalAmountDue > 0 ? customerDetails.paymentInfo.totalAmountDue : '0'"
                      (rightHeaderBtnCallBack)="openDetailsModal()"
    >
    </cma-complex-card>

    <!-- Payment Amount -->
    <cma-complex-card [cardTitle]="'Payment Amount'"
                      [extraContainerCssClasses]="'mb-8'"
                      [extraHeaderCssClasses]="'justify-between p-12'">
        <div class="complexCardContent">
            <div class="flex flex-col p-12">
                <div class="flex flex-row justify-between mb-6">
                    <span>Payment Amount</span>
                    <span>{{ customerDetails.paymentInfo.paymentAmount | currency }}</span>
                </div>
                <div class="flex flex-row justify-between mb-6">
                    <span>Convenience Fee</span>
                    <span>{{ customerDetails.paymentInfo.convenienceFee | currency }}</span>
                </div>
                <div class="flex flex-row justify-between mb-6 font-bold">
                    <span>Total Payment Amount</span>
                    <span>{{ customerDetails.paymentInfo.paymentAmount + customerDetails.paymentInfo.convenienceFee | currency }}</span>
                </div>
                <p class="font-normal text-justify"
                   *ngIf="customerDetails.paymentInfo.paymentAmount < +customerDetails.paymentInfo.totalAmountDue">
                    {{ partialPaymentWarning }}
                </p>
                <p class="mt-2 font-normal text-justify"
                   *ngIf="customerDetails.paymentInfo.paymentAmount > +customerDetails.paymentInfo.totalAmountDue">
                    {{ creditPaymentWarning }}
                </p>
            </div>
        </div>
    </cma-complex-card>

    <!-- middle screen text-->
    <p class="px-12 text-gray-dark text-left text-xs">
        Speedpay is a third-party vendor that charges a small convenience fee to process payments.
        No part of this fee is made payable to Duke Energy.
    </p>

    <!-- Payment Date-->
    <cma-complex-card [cardTitle]="'Payment Date'"
                      [extraContainerCssClasses]="'my-18'"
                      [extraHeaderCssClasses]="'justify-between p-12'">
        <div class="complexCardContent">
            <div class="flex flex-col p-12">
                <div class="flex flex-row justify-between mb-4">
                    <span>Today</span>
                    <span>{{ today | date }}</span>
                </div>
            </div>
        </div>
    </cma-complex-card>
</div>

<!-- Account Details Modal -->
<div [hidden]="!isAccountDetailsModalOpen">
    <cma-modal (modalClosed)="closeDetailsModal()"
               [allowClose]="true"
               [contentTemplate]="detailsModalTemplate"
               [isOpen]="isAccountDetailsModalOpen"
               [alignment]="'bottom'"
               [primaryBtnText]="''"
               [secondaryBtnText]="''"
               [title]="'Account Details'"
               [titleTwClasses]="''"
    >
    </cma-modal>

    <ng-template #detailsModalTemplate>
        <div class="text-left container-xs md:container-6xl p-12 font-normal border border-gray rounded-md">
            <p class="m-0">{{ customerDetails.customerInfo.customerName.firstName }} {{ customerDetails.customerInfo.customerName.lastName }}</p>
            <p class="m-0">Account #{{ customerDetails.customerInfo.accountNumber }}</p>
            <!-- Customer Address -->
            <div class="m-0">
                <p class="p-0 m-0">
                    {{ customerDetails.customerInfo.address.houseNumber }}
                    {{ customerDetails.customerInfo.address.address1 }}
                    {{ customerDetails.customerInfo.address.address2 }}</p>
                <p class="p-0 m-0">{{ customerDetails.customerInfo.address.city }},
                    {{ customerDetails.customerInfo.address.state }} {{ customerDetails.customerInfo.address.zipCode }}</p>
            </div>

            <div class="my-8">
                <p class="m-0 font-bold"
                   [ngClass]="customerDetails.cpiMessage.messagePriority === 'HIGH' ? 'text-red-dark' : 'text-grey-dark'">
                    {{ customerDetails.paymentInfo.totalAmountDue | currency }}
                </p>
                <p class="m-0 text-sm font-normal">Payment due
                    on {{ customerDetails.paymentInfo.paymentDueDate | date }}</p>
            </div>

            <eds-inline-banner
                    [extraTwClasses]="'my-24'"
                    [status]="customerDetails.cpiMessage.messagePriority === 'HIGH' ? 'error' : 'info'"
                    [message]="customerDetails.cpiMessage.messageBody"
            ></eds-inline-banner>
        </div>
    </ng-template>
</div>

