import {CustomerDetails} from "../speed-pay/models/interfaces/customer-details";

export const mockedData: CustomerDetails = {
    paymentInfo: {
        paymentAmount: 262.21,
        totalAmountDue: '320.21',
        convenienceFee: 1.25,
        paymentDueDate: '2024-09-17',
        paymentMethod: 'GooglePay'
    },
    customerInfo: {
        customerName: {
            firstName: 'Joseph',
            lastName: 'Manzewitsch'
        },
        billerId: 'CRNC',
        accountNumber: '910124166713',
        address: {
            address1: '6206 BIRKDALE VALLEY DR',
            address2: 'APT 523',
            city: 'CHARLOTTE',
            houseNumber: '1143',
            state: 'NC',
            zipCode: '28277'
        }
    },
    tokens: {
        cmaToken: 'jnK2VuyTUdlWo1B8erEG8nt3Tsvyx9mwhKACygR7oD2FvQmg7vJr12S86yPzUt5O',
        aciToken: 'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTUxMiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiZHVrZWFwcCIsInN1YiI6ImR1a2VhcHAiLCJhenAiOiJkdWtlYXBwIiwidXNlcm5hbWUiOiJ0a24uZXhjLmR1a2VhcHAiLCJDbGllbnRJZCI6ImR1a2UiLCJTaXRlSWQiOiJkdWtlIiwiVXNlcklkIjoiZHVrZWFwcCIsIlVzZXJHcm91cCI6ImR1a2VhcHAiLCJjcmVkaXRBY2NvdW50IjoiMzA3RkEwQTNGQUE2QUE5QTMyMEY5ODI2RkE2MkM0QjQ4NkZDRDFENkNCM0I0M0ZFMDE5MzE4RUIzREQ5RkJBNSIsInBlcm1pc3Npb25zIjpbIkEiLCJEIiwiRSIsIlMiLCI1IiwiRiIsIkNWQSJdLCJleHAiOjE3MzA5MTUyNTh9.jSv3nBzTI1xW5atWRXQLHNykbHoYWczAQL7b6wTSMMtCKhiZkK7IPy3_PhNEA-y32lHaZXlw8ZVAbvUkGxF6Mw'
    },
    cpiMessage: {
        messageBody: 'CPI message CPI message CPI message CPI message CPI message CPI message CPI message CPI message CPI message CPI message CPI message',
        messagePriority: 'HIGH'
    },
}
