import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ListenerEvent, ListenerEventsType} from "src/app/speed-pay/models/enums/listener-events-type";


@Injectable({
    providedIn: 'root'
})
export class EventBusService {
    private _eventBus = new BehaviorSubject<ListenerEventsType>(ListenerEventsType.APP_INIT);

    public get eventBus() {
        return this._eventBus.asObservable();
    }

    public emit(eventType: ListenerEventsType): void {
        this._eventBus.next(eventType);
    }
}
