import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {
    EdsFormHintsComponent,
    EdsIconButtonComponent,
    EdsIconMeta,
    EdsSvgComponent
} from '@electronds/ng-components-piedmont';
import {CommonModule, CurrencyPipe} from "@angular/common";
import {EventBusService} from "src/app/core/services/event-bus.service";
import {ListenerEventsType} from "src/app/speed-pay/models/enums/listener-events-type";

@Component({
    selector: 'cma-complex-card',
    standalone: true,
    imports: [CommonModule, EdsFormHintsComponent, EdsSvgComponent, EdsIconButtonComponent, CurrencyPipe],
    templateUrl: './complex-card.component.html',
})
export class ComplexCardComponent implements OnInit {
    // dependencies
    private _eventBusService = inject(EventBusService);

    @Input()
    public btnIconName = '';
    @Input()
    public cardTitle = '';
    @Input()
    public extraBtnCssClasses = '';
    @Input()
    public extraContainerCssClasses = '';
    @Input()
    public extraHeaderCssClasses = '';
    @Input()
    public extraTitleCssClasses = '';
    @Input()
    public rightHeaderAmount = '';
    @Input()
    public rightHeaderBtnText = '';
    @Output()
    public rightHeaderBtnCallBack: EventEmitter<void> = new EventEmitter<void>();
    public imageData!: EdsIconMeta;

    public emitBtnClicked(): void {
        this._eventBusService.emit(ListenerEventsType.INFO_ICON_TAPPED);
        this.rightHeaderBtnCallBack.emit();
    }

    public ngOnInit(): void {
        this.imageData = {
            src: this.btnIconName,
            iconClass: this.extraBtnCssClasses,
            altText: 'More Details',
        };
    }
}

