import {inject, Injectable} from "@angular/core";
import {CustomerDetails} from "src/app/speed-pay/models/interfaces/customer-details";
import {mockedData} from "../mocked-data";
import {EventBusService} from "./event-bus.service";
import {ListenerEventsType} from "../../speed-pay/models/enums/listener-events-type";

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    // dependencies
    private _eventBus = inject(EventBusService);

    private sessionInfo: CustomerDetails = null;

    public getAciAccessToken(): string {
        return this.sessionInfo.tokens.aciToken;
    }

    public getAccountNumber(): string {
        return this.sessionInfo.customerInfo.accountNumber;
    }

    public getPaymentMethod(): 'GooglePay' | 'ApplePay' {
        return this.sessionInfo.paymentInfo.paymentMethod;
    }

    public getPaymentAmount(): number {
        return this.sessionInfo.paymentInfo.paymentAmount;
    }

    public getConvenienceFee(): number {
        return this.sessionInfo.paymentInfo.convenienceFee;
    }

    public getCMAToken(): string {
        return this.sessionInfo.tokens.cmaToken;
    }

    public getSessionInfoObject(): CustomerDetails {
        const sessionObject = sessionStorage.getItem('sessionInfo');
        if (!sessionObject) {
            this._eventBus.emit(ListenerEventsType.SESSION_INFO_MISSING);
            return null;
        }

        try {
            this.sessionInfo = this.sessionInfo ?? JSON.parse(sessionObject);
        } catch (e: any) {
            this._eventBus.emit(ListenerEventsType.SESSION_INFO_INVALID_JSON);
        }

        // get any errors of missing fields
        const sessionInfoFieldsErrors = this.validateSessionInfoFields(this.sessionInfo);
        // get any errors of missing values
        const sessionInfoValuesErrors = this.validateSessionInfoValues(this.sessionInfo);

        if (sessionInfoFieldsErrors.length > 0) {
            this._eventBus.emit(ListenerEventsType.SESSION_INFO_OBTAIN_WITH_MISSING_FIELDS);
        } else if (sessionInfoValuesErrors.length > 0) {
            this._eventBus.emit(ListenerEventsType.SESSION_INFO_OBTAIN_WITH_MISSING_VALUES);
        } else {
            this._eventBus.emit(ListenerEventsType.SESSION_INFO_OBTAIN);
        }
        return this.sessionInfo;
    }

    public validateSessionInfoFields(sessionInfo: CustomerDetails): string[] {
        const missingFields = [];
        // those are the required keys in the sessionInfo Object
        const parentKeys = ['paymentInfo', 'customerInfo', 'tokens'];
        const paymentInfoKeys = ['paymentMethod'];
        const customerInfoKeys = ['billerId', 'accountNumber'];
        const tokensKeys = ['aciToken', 'aciToken'];

        parentKeys.forEach(key => {
            if (sessionInfo && !(key in sessionInfo)) {
                missingFields.push(`parent key ${key}`);
            }
        });

        paymentInfoKeys.forEach(key => {
            if (sessionInfo.paymentInfo && !(key in sessionInfo.paymentInfo)) {
                missingFields.push(`${key} of paymentInfo`);
            }
        });

        customerInfoKeys.forEach(key => {
            if (sessionInfo.customerInfo && !(key in sessionInfo.customerInfo)) {
                missingFields.push(`${key} of customerInfo`);
            }
        });

        tokensKeys.forEach(key => {
            if (sessionInfo.tokens && !(key in sessionInfo.tokens)) {
                missingFields.push(`${key} of tokens`);
            }
        });

        return missingFields;
    }

    public validateSessionInfoValues(sessionInfo: CustomerDetails): string[] {
        const missingValues = [];

        const parentKeys = ['paymentInfo', 'customerInfo', 'tokens'];
        const paymentInfoKeys = ['paymentMethod'];
        const customerInfoKeys = ['billerId', 'accountNumber'];
        const tokensKeys = ['aciToken', 'aciToken'];

        parentKeys.forEach(key => {
            if (sessionInfo && !(sessionInfo[key])) {
                missingValues.push(`value of sessionInfo.${key}`);
            }
        });

        paymentInfoKeys.forEach(key => {
            if (sessionInfo.paymentInfo && !(sessionInfo.paymentInfo[key])) {
                missingValues.push(`value of paymentInfo.${key}`);
            }
        });

        customerInfoKeys.forEach(key => {
            if (sessionInfo.customerInfo && !(sessionInfo.customerInfo[key])) {
                missingValues.push(`value of customerInfo.${key}`);
            }
        });

        tokensKeys.forEach(key => {
            if (sessionInfo.tokens && !(sessionInfo.tokens[key])) {
                missingValues.push(`value of tokens.${key}`);
            }
        });

        return missingValues;
    }

    public setSessionInfoObject(): void {
        sessionStorage.setItem('sessionInfo', JSON.stringify(mockedData));
    }
}