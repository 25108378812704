<div class="container">
    <div class="mx-24">
        <div class="text-md flex flex-col items-center my-12">
            <eds-svg [svgSizeClass]="'icon-24'"
                     [svgPath]="'/assets/icons/info.svg'">
            </eds-svg>
            <h2 class="mt-8 text-xl text-center"
                [ngClass]="errorType === 'SystemError' ? 'text-grey-dark' : 'text-red-dark'">
                {{errorHeader}}
            </h2>
            <p class="mt-8 text-md text-center"
               [ngClass]="errorType === 'SystemError' ? 'text-grey-dark' : 'text-red-dark'">
                {{ errorMessage }}
            </p>
        </div>
    </div>
</div>
