<!-- spinner -->
<div *ngIf="isLoading$ | async"
     class="fixed inset-0 z-modal">
    <div class="flex justify-center absolute inset-0 bg-gray-darker bg-opacity-80">
        <img class="absolute top-1/2 icon-80"
             src="assets/icons/loading.svg"
             width="80"
             height="80"
             alt="loading data"/>
    </div>
</div>

<!-- landing screen -->
<section *ngIf="!isPaymentSubmitted && !isError"
         class="container mx-auto p-20 pt-36">
    <div [hidden]="(isLoading$ | async)" class="flex flex-col justify-between">
        <cma-landing class="h-full"
                     [customerDetails]="customerDetails">
        </cma-landing>
        <div class="fixed bottom-20 left-20 right-20">
            <div style="width:100%" class="apple-pay-button" data-aci-speedpay="apple-pay"></div>
            <div class="google-pay-button" data-aci-speedpay="google-pay"></div>
        </div>
    </div>
</section>

<!-- confirmation screen -->
<section *ngIf="isPaymentSubmitted && !isError">
    <cma-payment-confirmation [paymentResponse]="paymentResponse"></cma-payment-confirmation>
</section>

<!-- error screen -->
<section *ngIf="!(isLoading$ | async) && isError">
    <cma-payment-error [errorType]="'SystemError'"
                       [errorHeader]="'We\'re unable to process payments at this time.'"
                       [errorMessage]="'Sorry, there was an issue processing your request. Please try again later.'">

    </cma-payment-error>
</section>


