import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loader$: Subject<boolean> = new BehaviorSubject<boolean>(true);

  public getLoader():  Observable<boolean> {
    return this.loader$.asObservable();
  }

  public setLoader(isLoading:  boolean): void {
    this.loader$.next(isLoading);
  }
}
