import {inject, Injectable} from "@angular/core";
import {SdkInitOptions} from "../models/interfaces/sdk-init-options";
import {SessionStorageService} from "src/app/core/services/session-storage.service";
import {ISdkInitResponse} from "../models/interfaces/sdk-init-response";
import {catchError, Observable, throwError} from "rxjs";
import {SendPaymentRequest} from "../models/interfaces/send-payment-request";
import {ProviderService} from "./provider.service";
import {SendPaymentResponse} from "../models/interfaces/send-payment-response";

@Injectable({
    providedIn: 'root'
})
export class SpeedPayService {
    private _sessionStorageService = inject(SessionStorageService);
    private _providerService = inject(ProviderService);

    public initSpeedPayScript(aci: any): Promise<ISdkInitResponse> {
        const options: SdkInitOptions = {
            accessToken: this._sessionStorageService.getAciAccessToken(),
            billerAccountId: this._sessionStorageService.getAccountNumber(),
            paymentMethod: this._sessionStorageService.getPaymentMethod(),
            userInterface: {
                iframe: {
                    width: '100%'
                },
                button: {
                    width: '100%',
                    color: 'black',
                }
            }
        };

        return aci.speedpay.fundingAccountGateway.init(options);
    }

    public sendPayment(payload: SendPaymentRequest): Observable<SendPaymentResponse> {
        return this._providerService.sendPayment(payload)
            .pipe(
                catchError((err) => throwError(() => err))
            );
    }
}