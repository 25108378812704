import {Component, Input} from '@angular/core';
import {CurrencyPipe, DatePipe, NgClass, NgIf} from "@angular/common";
import {PaymentConfirmationUiModel} from "src/app/speed-pay/models/interfaces/payment-confirmation-ui-model";
import {EdsInlineBannerComponent, EdsSvgComponent} from "@electronds/ng-components-piedmont";

@Component({
    selector: 'cma-payment-confirmation',
    standalone: true,
    imports: [
        CurrencyPipe,
        DatePipe,
        NgIf,
        NgClass,
        EdsSvgComponent,
        EdsInlineBannerComponent
    ],
    templateUrl: './payment-confirmation.component.html'
})
export class PaymentConfirmationComponent {
    @Input() public paymentResponse: PaymentConfirmationUiModel;

    public today = Date.now();
}
